export default {
  "en-GB": {
    addressZip: "postcode",
  },
  "en-US": {
    addressZip: "zipcode",
  },
  "en-CA": {
    addressZip: "zipcode",
  },
};
