import axios from "axios";

const startSignup = (email) => {
  return axios.post("/api/auth/signup", {
    email,
  });
};

const startTokenlessSignup = async (email) => {
  try {
    const resp = await axios.post("/api/auth/tokenless_signup", {
      email,
    });
    return {
      data: resp.data,
      err: undefined,
    };
  } catch (err) {
    console.log(err);
    return {
      data: undefined,
      err: "unable to start tokenless signup",
    };
  }
};

const confirmSession = (email, token) => {
  return axios.post("/api/auth/session", {
    email,
    token,
  });
};

const refreshToken = (token) => {
  return axios.put("/api/auth/session", {
    refresh_token: token,
  });
};

const logIn = (email) => {
  return axios.post("/api/auth/login", {
    email,
  });
};

const logOut = (token) => {
  return axios.delete("/api/auth/session", {
    data: {
      access_token: token,
    },
  });
};

export default {
  startSignup,
  startTokenlessSignup,
  confirmSession,
  refreshToken,
  logIn,
  logOut,
};
