export function isChatPublic() {
  if (window.location.hostname === "localhost") {
    return true;
  }

  if (window.location.hostname.endsWith("shamaazi.io")) {
    return true;
  }

  return false;
}
