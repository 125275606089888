import axios from "axios";

import AuthAPI from "./../api/auth";
import UserSvc from "./../service/user";

export const setInterceptors = () => {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (
        !err ||
        err.config.url === "/api/auth/session" ||
        err.response.status !== 401
      ) {
        return Promise.reject(err);
      }

      return AuthAPI.refreshToken(UserSvc.getRefreshToken())
        .then((res) => {
          UserSvc.setAccessToken(res.data.access_token);
          UserSvc.setIDToken(res.data.id_token);

          // retry the original request
          let originalRequest = err.config;
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${res.data.id_token}`;

          return new Promise((resolve, reject) => {
            axios
              .request(originalRequest)
              .then((res) => {
                return resolve(res);
              })
              .catch((err) => {
                return reject(err);
              });
          });
        })
        .catch((err) => {
          err.isAuth = true;
          if (err.config.url === "/api/auth/session") {
            //refresh token request failed
            UserSvc.clearSession();
            if (window.location.pathname !== "/chat") {
              window.location = "/";
            }
          }

          return Promise.reject(err);
        });
    }
  );
};

export const logOut = async (accessToken) => {
  try {
    AuthAPI.logOut(accessToken);
    UserSvc.clearSession();
    window.location = "/";
  } catch (err) {
    console.log(err);
    alert("There was a problem logging you out");
  }
};

export default {
  setInterceptors,
  logOut,
};
