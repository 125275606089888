const detectLocale = () => {
  const info = Intl.DateTimeFormat().resolvedOptions();

  window.sessionStorage.setItem("__zch__user-locale-detected", info.locale);
};

const getDetectedLocale = () => {
  return window.sessionStorage.getItem("__zch__user-locale-detected");
};

const setAccessToken = (token) => {
  window.sessionStorage.setItem("__zch__user-access-token", token);
};

const getAccessToken = () => {
  return window.sessionStorage.getItem("__zch__user-access-token");
};

const isAuthenticated = () => {
  return !!window.sessionStorage.getItem("__zch__user-access-token");
};

const setIDToken = (token) => {
  window.sessionStorage.setItem("__zch__user-id-token", token);
};

const getIDToken = () => {
  return window.sessionStorage.getItem("__zch__user-id-token");
};

const setRefreshToken = (token) => {
  window.sessionStorage.setItem("__zch__user-refresh-token", token);
};

const getRefreshToken = () => {
  return window.sessionStorage.getItem("__zch__user-refresh-token");
};

const setUser = (user) => {
  window.sessionStorage.setItem("__zch__user", JSON.stringify(user));
};

const setCharityId = (charityId) => {
  window.sessionStorage.setItem("__zch__charity__id__", charityId);
};

const getCharityId = () => {
  return window.sessionStorage.getItem("__zch__charity__id__");
};

const clearSession = () => {
  setIDToken("");
  setAccessToken("");
  setRefreshToken("");
};

export default {
  detectLocale,
  getDetectedLocale,
  setAccessToken,
  getAccessToken,
  isAuthenticated,
  setIDToken,
  getIDToken,
  setRefreshToken,
  getRefreshToken,
  setUser,
  clearSession,
  setCharityId,
  getCharityId,
};
